export const priorityOptions = ['Normal', 'Highest', 'High', 'Low', 'Lowest'];

export const categories = ['System', 'Process', 'Organisation', 'Data'];

export const fitOptions = [
  'bad',
  'marginal',
  'acceptable',
  'good',
  'excellent',
];
