<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';
import {
  categories,
  fitOptions,
  priorityOptions,
} from '@/modules/fit-gap/constants';

export default defineComponent({
  name: 'FitGapItem',
  components: {},
  mixins: [],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      step: null,

      form: {
        category: '',
        desired_situation: '',
        current_situation: '',
        gap_description: '',
        gap_priority: '',
        gap_severity: '',
        fit: '',
      },

      priorityOptions: priorityOptions,
      categories: categories,
      fitOptions: fitOptions,
    };
  },
  computed: {
    ...mapGetters(['project', 'sbsRecords']),
    ...mapGetters({}),
  },
  watch: {},
  mounted() {},
  methods: {
    stringToConstantColor,
  },
});
</script>

<template>
  <v-card
    class="my-1"
    elevation="0"
    :class="{ selected: selected }"
    :ripple="false"
    @click="$emit('click')"
  >
    <v-card-text>
      <div class="d-flex align-start card-content">
        <div class="d-flex flex-column flex-shrink-0 mr-2">
          <v-avatar
            :color="stringToConstantColor(value.category) + '80'"
            size="40"
            class=""
          >
            <span class="text--black text-h6"
              >{{ value.category.charAt(0) }}{{ value.friendly_id }}</span
            >
          </v-avatar>
          <small class="d-flex justify-center">{{ value.gap_priority }}</small>
        </div>
        <div class="d-flex flex-grow-1 card-content">
          <div class="column-center-content">
            {{ value.desired_situation }}
          </div>
          <div class="column-center-content">{{ value.current_situation }}</div>
          <div class="column-center-content">{{ value.gap_description }}</div>
        </div>
        <div class="flex-shrink-0 fit text-right">
          <v-chip>{{ value.fit }}</v-chip>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.v-card {
  border: 2px solid transparent;
}
.v-sheet.v-card {
  border-radius: 0;
}
.v-card--link:before {
  content: unset;
}
.selected {
  background-color: rgba(224, 239, 255, 0.6);
}
.fit {
  width: 100px;
}
.column-center-content {
  display: flex;
  justify-items: center;
  flex-grow: 1;
  flex-basis: 50%;
}
.card-content {
  display: flex;
  column-gap: 1rem;
}
</style>
