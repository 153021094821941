<template>
  <div class="d-flex flex-column fill-height">
    <div class="viewer-wrapper pos-rel">
      <div id="gis-viewer" />
      <ant-view-loading :loading="$wait.is(`tasks.get.*`)" />
    </div>
  </div>
</template>

<script>
import { loadModules, setDefaultOptions } from 'esri-loader';
import { mapGetters } from 'vuex';
import AntViewLoading from '@/components/AntViewLoading.vue';
import { GIS_VIEWER_WIDGETS } from '@/components/Modules/GisViewer/index';
import appConfig from '@/appConfig';

export default {
  name: 'GisViewerMap',
  components: { AntViewLoading },
  props: {
    defaultViewScene: {
      type: String,
      default: '2d',
    },
    widgets: {
      type: Object,
      default: () => ({
        [GIS_VIEWER_WIDGETS.RFI]: true,
      }),
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['selectedSbsObject', 'tasksRfi']),
    ...mapGetters('gisViewer', {
      viewer: 'viewer',
    }),
  },
  watch: {
    selectedSbsObject() {
      this.highlightSbsCode();
    },
  },
  mounted() {
    setDefaultOptions({ version: '4.26', css: true });

    loadModules([
      'esri/config',
      'esri/Map',

      // views
      'esri/views/ui/UI',
      'esri/views/MapView',
      'esri/views/SceneView',

      // Layers
      'esri/layers/GroupLayer',
      'esri/layers/FeatureLayer',
      'esri/layers/ImageryLayer',
      'esri/layers/MapImageLayer',
      'esri/layers/TileLayer',
      'esri/layers/VectorTileLayer',
      'esri/layers/WMSLayer',
      'esri/layers/PointCloudLayer',
      'esri/layers/SceneLayer',
      'esri/layers/BuildingSceneLayer',
      'esri/layers/VoxelLayer',
      'esri/layers/support/Sublayer',
      'esri/layers/GraphicsLayer',
      'esri/layers/WFSLayer',

      // Widgets
      'esri/widgets/LayerList',
      'esri/widgets/Search',
      'esri/widgets/Home',
      'esri/widgets/FeatureTable',
      'esri/widgets/Zoom',
      'esri/widgets/BasemapToggle',
      'esri/widgets/BasemapGallery',
      'esri/widgets/Measurement',
      'esri/widgets/Sketch',
      'esri/widgets/Feature',

      // Classes
      'esri/Basemap',
      'esri/portal/Portal',
      'esri/Graphic',
      'esri/geometry/Geometry',
      'esri/geometry/SpatialReference',

      //Objects
      'esri/geometry/projection',

      //Functions
      'esri/request',
    ])
      .then(
        async ([
          esriConfig,
          Map,

          // views
          UI,
          MapView,
          SceneView,

          // Layers
          GroupLayer,
          FeatureLayer,
          ImageryLayer,
          MapImageLayer,
          TileLayer,
          VectorTileLayer,
          WMSLayer,
          PointCloudLayer,
          SceneLayer,
          BuildingSceneLayer,
          VoxelLayer,
          Sublayer,
          GraphicsLayer,
          WFSLayer,

          // Widgets
          LayerList,
          Search,
          Home,
          FeatureTable,
          Zoom,
          BasemapToggle,
          BasemapGallery,
          Measurement,
          Sketch,
          Feature,

          // Classes
          Basemap,
          Portal,
          Graphic,
          Geometry,
          SpatialReference,

          //Objects
          projection,

          // Functions
          request,
        ]) => {
          const classes = {
            esriConfig,
            Map,

            // views
            UI,
            MapView,
            SceneView,

            // Layers
            GroupLayer,
            FeatureLayer,
            ImageryLayer,
            MapImageLayer,
            TileLayer,
            VectorTileLayer,
            WMSLayer,
            PointCloudLayer,
            SceneLayer,
            BuildingSceneLayer,
            VoxelLayer,
            Sublayer,
            GraphicsLayer,
            WFSLayer,

            // Widgets
            LayerList,
            Search,
            Home,
            FeatureTable,
            Zoom,
            BasemapToggle,
            BasemapGallery,
            Measurement,
            Sketch,
            Feature,

            // Classes
            Basemap,
            Portal,
            Graphic,
            Geometry,
            SpatialReference,

            //Objects
            projection,

            // Functions
            request,
          };
          this.$store.dispatch('gisViewer/setViewerProp', {
            key: 'classes',
            value: classes,
          });

          const config = {};
          if (appConfig.VUE_APP_DEBUG_GIS_TOKEN) {
            config.token = appConfig.VUE_APP_DEBUG_GIS_TOKEN;
          }
          await this.$store.dispatch('gisViewer/loadMap', config);
          await this.$store.dispatch('gisViewer/loadViewMap', {
            container: 'gis-viewer',
          });
          await this.$store.dispatch('gisViewer/loadViewScene');

          // Not found why, but loading the 3d view without calling 2d
          // can't load map and layers properly
          await this.$store.dispatch('gisViewer/switchToScene', '2d');
          if (this.defaultViewScene !== '2d') {
            await this.$store.dispatch(
              'gisViewer/switchToScene',
              this.defaultViewScene
            );
          }

          this.viewer.viewActive
            .when(() => {
              this.$store.dispatch('gisViewer/loadSearchSources');
            })
            .then(async () => {
              if (this.widgets[GIS_VIEWER_WIDGETS.RFI]) {
                await this.$store.dispatch(
                  'gisViewer/loadRfiLayer',
                  this.tasksRfi
                );
                await this.parseInitialPosition();
                if (this.$route.query.sbscode) {
                  this.highlightSbsCode()
                }
              }
            });
        }
      )
      .catch((e) => console.error(e));
  },
  methods: {
    async parseInitialPosition() {
      const params = new URLSearchParams(window.location.search);
      return this.$store.dispatch('gisViewer/goTo', {
        x: params.get('x'),
        y: params.get('y'),
        center: params.get('center')?.split(';'),
        rfiId: params.get('rfiid'),
      });
    },
    highlightSbsCode() {
      let rfis = this.tasksRfi;
      this.$store.dispatch('gisViewer/clearCustomGraphics');
      const promises = [];
      if (this.selectedSbsObject) {
        rfis = rfis.filter(
          (rfi) => rfi.sbscode?.code === this.selectedSbsObject?.code
        );
        promises.push(
          this.$store.dispatch('gisViewer/loadRfiDrawingsLayer', rfis)
        );
      }
      promises.push(this.$store.dispatch('gisViewer/loadRfiLayer', rfis));
      Promise.all(promises).then(() => {
        this.$store.dispatch('gisViewer/centerRfis');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.viewer-wrapper {
  display: flex;
  flex-grow: 1;

  #gis-viewer {
    padding: 0;
    margin: 0;
    height: 100%;
    //width: 100%;
    flex: 1 1 auto;
    position: relative;
  }
}

.feature-table-wrapper {
  flex-grow: 1;

  #featureTable {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    position: relative;
  }
}

:deep(.esri-layer-list) {
  ul {
    padding-left: 0;
  }
}
</style>
<style>
.esri-attribution__powered-by {
  display: none;
}
.esri-ui {
  .esri-zoom {
    display: none;
  }
  .esri-search {
    display: none;
  }
  .esri-home {
    display: none;
  }
}
</style>
