import { mapGetters } from 'vuex';
import { APP_TITLE } from '@/App.vue';

export default {
  computed: {
    ...mapGetters(['project']),
  },
  metaInfo() {
    const crumbs = [this.project?.name, this.moduleName].filter(Boolean);
    return {
      title: `${APP_TITLE} ${crumbs.length ? `- ${crumbs.join(', ')}` : ''}`,
    };
  },
};
