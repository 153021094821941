<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';

export default defineComponent({
  name: 'FitGapTaskItem',
  components: {},
  mixins: [],
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => {
    return {
      step: null,
    };
  },
  computed: {
    ...mapGetters(['project']),
    ...mapGetters({}),
    statusColor() {
      switch (this.task.status) {
        case 'open':
          return 'success';
        case 'canceled':
          return 'warning';
        case 'closed':
          return 'error';
        default:
          return 'success';
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    stringToConstantColor,
  },
});
</script>

<template>
  <v-card class="my-1" :ripple="false" @click="$emit('click', task)">
    <v-card-text class="py-1 px-3">
      <div class="d-flex align-center">
        <div class="d-flex flex-column flex-shrink-0 mr-2">
          <v-avatar :color="'primary'" class="mr-2 white--text" size="40">
            {{
              `${task?.assigned_to?.firstname.charAt(0) || 'U'}${task?.assigned_to?.lastname.charAt(0) || ''}`
            }}
          </v-avatar>
        </div>
        <div class="d-flex flex-column flex-grow-1 overflow-hidden">
          <div class="text-h6 text-capitalize">
            {{ task?.title }}
          </div>
          <div>{{ task?.description }}</div>
        </div>
        <div class="flex-shrink-0 text-right">
          <v-chip
            class="fit flex justify-center text-capitalize"
            :color="statusColor"
            >{{ task?.status }}</v-chip
          >
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.v-card {
  border: 2px solid transparent;
}
.fit {
  width: 100px;
}
</style>
