<template>
  <div class="d-flex align-center flex-grow-1">
    <v-icon v-if="loading" class="mr-2 mb-2 mdi-spin"> mdi-loading </v-icon>
    <v-icon
      v-else-if="isLinkMode && isLinked"
      class="mr-2 mb-2 cursor-pointer"
      @click="$store.dispatch('swotScenarios/unlinkSwotToScenario', record.id)"
    >
      mdi-link-off
    </v-icon>
    <v-icon
      v-else-if="isLinkMode"
      class="mr-2 mb-2 cursor-pointer"
      @click="$store.dispatch('swotScenarios/linkSwotToScenario', record.id)"
    >
      mdi-link-plus
    </v-icon>
    <swot-item
      :record="record"
      :active="
        selectedStakeholder && !isLinkMode
          ? record.stakeholder_id === selectedStakeholder?.id
          : true
      "
    />
  </div>
</template>
<script>
import SwotItem from '@/components/Modules/StakeholderAnalyis/SwotItem.vue';

export default {
  name: 'SwotTableRow',
  components: { SwotItem },
  props: {
    record: {
      type: Object,
      required: true,
    },
    selectedStakeholder: {},
    isLinkMode: {},
    isLinked: {},
    loading: {},
    active: {},
  },
};
</script>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
